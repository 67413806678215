module.exports = {
    ORIGIN: 'https://livingstreamvdo.com/',
    // ORIGIN: 'http://ec2-3-128-1-135.us-east-2.compute.amazonaws.com/',
    // ORIGIN: 'http://localhost:3000/',
    HORSE_RACING: 'Horse racing',
    SOCCER: 'FOOTBALL',
    CRICKET: 'CRICKET',
    BASEBALL: 'BASEBALL',
    DEFAULT: '',
    PLAYERURL: 'https://e765432.xyz/static/566de249b718a185464725818dd5a65d16a0ab20/getdata.php?'
}