var axios = require('axios');
var API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseUrl: '',
  withCredentials: false,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
  /* other custom settings */
});

module.exports = API;