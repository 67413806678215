import React from 'react';
import Home from "./component/home/index";
import './App.scss';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="main_wrapper">
          <Switch>
            <Route exact path="/" component={Home}/>
          </Switch>
      </div>
    </Router>
  );
}

export default App;